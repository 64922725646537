import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {AuthenticationService} from '../services';

export const RoleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  const router: Router = inject(Router);
  const authService: AuthenticationService = inject(AuthenticationService);
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

  if (!authService.checkRole(['ROLE_EXTERNAL'])) {
    if (Object.keys(currentUser).length > 0 && window.dataLayer) {
      window.dataLayer.push({
        'uid': currentUser.id || currentUser?._id,
        'uemail': currentUser.email,
        'ucreated': currentUser.creationDate,
      });
    }

    return true;
  } else {
    router.navigate(['/']).then();
    return false;
  }
};
