import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import {PlanType} from '../../shared/enums';
import {canUser, HelperService} from '../../shared/helpers';

export const FeatureHasPermission: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  const userPlan: PlanType = inject(HelperService).getUserPlan();

  return canUser('setNavigation', userPlan)
    ? inject(Router).createUrlTree(['/home'])
    : true;
};
