import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {catchError, map, Observable, of} from 'rxjs';

import {ShareReportService} from '../services';
import {SharedAuth} from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ShareReportGuard  {
  constructor(
    private router: Router,
    private shareReportService: ShareReportService
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const shareId = segments[1]?.path;

    return this.shareReportService.getShareAuth(shareId).pipe(
      map((res) => {
        const response = res as SharedAuth;
        sessionStorage.setItem('sharedAuth', JSON.stringify(res));
        return !!response?.shareId;
      }),
      catchError(() => {
        sessionStorage.setItem(
          'sharedAuth',
          JSON.stringify({message: 'Not Found'})
        );
        this.router.navigate(['/not-found']).then();
        return of(false);
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const sharedAuth = JSON.parse(sessionStorage.getItem('sharedAuth')!)
      ? JSON.parse(sessionStorage.getItem('sharedAuth')!)
      : null;
    return !!sharedAuth?.message;
  }
}
