import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateFn,
} from '@angular/router';
import {AuthenticationService} from '../services';

export const AuthenticationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  const router: Router = inject(Router);
  const authService: AuthenticationService = inject(AuthenticationService);

  if (authService.isAuthenticated()) {
    return true;
  }

  router.navigate(['/login']).then();
  return false;
};
