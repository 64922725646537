import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';

import {canUser, HelperService} from '../../shared/helpers';

export const UserPlanGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const router: Router = inject(Router);
  const helperService: HelperService = inject(HelperService);
  const url = state.url;

  const isIndividual = canUser('isIndividual', helperService.getUserPlan());
  const isBasic = canUser('isBasic', helperService.getUserPlan());

  if (url === '/connections/sign-in') {
    !isIndividual && router.navigate(['/']).then();
    return isIndividual;
  }

  if (url === '/clients') {
    isIndividual && router.navigate(['/']).then();
    return !isIndividual;
  }

  if (url?.indexOf('/clients/') > -1) {
    isIndividual && router.navigate(['/']).then();
    return !isIndividual;
  }

  if (url === '/monitoring') {
    (isIndividual || isBasic) && router.navigate(['/']).then();
    return !isIndividual && !isBasic;
  }

  if (url?.indexOf('/monitoring/') > -1) {
    (isIndividual || isBasic) && router.navigate(['/']).then();
    return !isIndividual && !isBasic;
  }

  return true;
};
