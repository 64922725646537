import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {BillingPlanStore} from 'src/app/shared/state';

export const FreeTrialGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  const router: Router = inject(Router);
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const billingPlanStore = inject(BillingPlanStore);
  const billingStatus =
    billingPlanStore?.getPlan()?.status ?? organization?.paymentStatus;

  if (billingStatus !== 'EXPIRED') {
    if (state.url === '/expired') {
      router.navigate(['/']).then();
      return false;
    } else {
      return true;
    }
  } else {
    if (state.url !== '/expired') {
      router.navigate(['/expired']).then();
    }
    return true;
  }
};
