import {Injectable} from '@angular/core';
import {Route, Router} from '@angular/router';
import {environment} from '../../../environments/environment';

import {OrganizationService} from '../services';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    private router: Router,
    private organizationService: OrganizationService
  ) {}

  canLoad(route: Route): boolean {
    const organization = localStorage.getItem('organization');
    const session = localStorage.getItem('currentUrlSession');
    const currentUser = localStorage.getItem('currentUser');

    if (
      currentUser ||
      (session && this.router.parseUrl(session).queryParams.session)
    ) {
      return true;
    }

    if (!organization) {
      this.organizationService.getOrg();
    } else if (
      (currentUser && JSON.parse(currentUser).organization.id) !=
      JSON.parse(organization).id
    ) {
      this.organizationService.getOrg();
    } else {
      window.location.href =
        `${environment.coreUrl}/login?orgId=` + JSON.parse(organization).id;
    }

    return false;
  }
}
